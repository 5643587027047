<template>
    <div class="page">
        <el-form size="small" class="query-form searchForm" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="areaCode" label="区域编号：">
                <el-input v-model.trim="searchForm.areaCode" maxlength="50" placeholder="请输入区域编号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="bookType" label="图书分类：">
                <el-select v-model="searchForm.bookType" placeholder="请选择图书分类" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('book_type')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="publishingHouse" label="出版社：">
                <el-select v-model="searchForm.publishingHouse" placeholder="请选择出版社" clearable class="w100i">
                    <el-option
                            :key="index"
                            :label="item"
                            :value="item"
                            v-for="(item, index) in publishingHouseOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="startPricing" label="定价(￥)：">
                <div class="flex_b_c">
                    <el-input v-model.trim="searchForm.startPricing" maxlength="4" placeholder="0-9999"
                              clearable @input="searchForm.startPricing = searchForm.startPricing.replace(/[^\d]/g, '')"></el-input>
                    <span class="m_l1 m_r1">-</span>
                    <el-input v-model.trim="searchForm.endPricing" maxlength="4" placeholder="0-9999"
                              clearable @input="searchForm.endPricing = searchForm.endPricing.replace(/[^\d]/g, '')"></el-input>
                </div>
            </el-form-item>
            <el-form-item prop="state" label="状态：">
                <el-select v-model="searchForm.state" placeholder="请选择状态" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('book_state')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="startPublicationTime" label="出版时间：">
                <div class="flex_b_c">
                    <el-date-picker
                            value-format="yyyy"
                            format="yyyy"
                            v-model="searchForm.startPublicationTime"
                            type="year" :picker-options="pickerOptions"
                            placeholder="请选择">
                    </el-date-picker>
                    <span class="m_l1 m_r1">-</span>
                    <el-date-picker
                            value-format="yyyy"
                            format="yyyy"
                            v-model="searchForm.endPublicationTime"
                            type="year" :picker-options="pickerOptions"
                            placeholder="请选择">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item prop="bookName" label="输入搜索：">
                <el-input v-model.trim="searchForm.bookName" maxlength="100" placeholder="请输入书名（限100字）"
                          clearable></el-input>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <div class="f_r">
                <el-button size="small" type="primary" icon="el-icon-plus" v-show="hasPermission('manageList:add')"
                           @click="addEdit(null, 'add')">新增
                </el-button>
                <el-button type="danger" size="small" icon="el-icon-delete" v-show="hasPermission('manageList:del')"
                           @click="del()">批量删除
                </el-button>
            </div>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 398px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable" row-key='id'>
                <el-table-column type="selection" fixed :reserve-selection='true'/>
                <el-table-column prop="areaCode" label="区域编号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="bookName" label="书名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="bookType" label="图书分类" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("book_type", scope.row.bookType ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="publishingHouse" label="出版社" show-overflow-tooltip></el-table-column>
                <el-table-column prop="publicationTime" label="出版时间" width="150" sortable show-overflow-tooltip></el-table-column>
                <el-table-column prop="bookNumber" label="份数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="pricing" label="定价（￥）" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("book_state", scope.row.state ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="200" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('manageList:view')"
                                   @click="addEdit(scope.row, 'view')">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('manageList:edit')"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                        <el-button type="text" size="small" v-if="hasPermission('manageList:del')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <ManageForm ref="manageForm" @refreshDataList="search()"></ManageForm>
    </div>
</template>

<script>
    import ManageForm from './manageForm'

    export default {
        components: {ManageForm},
        data() {
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getFullYear() < 1940 || time.getFullYear() > new Date().getFullYear()
                    }
                },
                publishingHouseOpt: [],
                searchForm: {
                    areaCode: '',
                    bookType: '',
                    publishingHouse: '',
                    startPricing: '',
                    endPricing: '',
                    state: '',
                    startPublicationTime: '',
                    endPublicationTime: '',
                    bookName: '',
                    current: 1,
                    size: 10,
                },
                loading: false,
                dataList: [],
                dataListSelections: [],
                total: 0,
            }
        },
        mounted() {
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                this.getPublishingHouseList()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.record.archivesbookList, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取出版社
            getPublishingHouseList() {
                this.$axios(this.api.record.publishingHouseList, this.searchForm, 'post').then(data => {
                    if (data.status) {
                        this.publishingHouseOpt = data.data
                    }
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.manageForm.init(row, method)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.archivesbookDeleteById, {list: ids}, 'put').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.$refs.multipleTable.clearSelection()
                            this.search()
                        } else {
                            this.$message.error('删除失败')
                        }
                    })
                })
            },
            // 重置
            reset() {
                this.searchForm.endPricing = ''
                this.searchForm.endPublicationTime = ''
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 33.33%;
        }
    }
</style>
